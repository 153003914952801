<template>
    <div class="modal-auth-register">
        <div class="modal-auth-register__content">
            <slot></slot>
            <h2 class="modal-auth-register__normal-title">
                {{ $lang.components.modalAuthRegister.register_with_social }}
            </h2>

            <ButtonGeneric
                v-for="info in rrss"
                :key="info.iconAlt"
                :info="info"
                type="button"
                class="modal-auth-register__social-button"
            ></ButtonGeneric>
            <form class="modal-auth-register__form-login" @submit.prevent="signUp">
                <div class="space-y-3">
                    <h2 class="modal-auth-register__normal-title">
                        {{ $lang.components.modalAuthRegister.register_email }}
                    </h2>
                    <InputGeneric
                        v-model="form.data.email"
                        class="modal-auth-register__field"
                        type="text"
                        name="email"
                        :placeholder="$lang.components.modalAuthRegister.email"
                        :invalid="form.validationStatus.email"
                    >
                        {{ form.validationStatus.email }}
                    </InputGeneric>
                    <InputGeneric
                        v-model="form.data.name"
                        class="modal-auth-register__field"
                        type="text"
                        name="name"
                        :placeholder="$lang.components.modalAuthRegister.username"
                        :invalid="form.validationStatus.name"
                    >
                        {{ form.validationStatus.name }}
                    </InputGeneric>
                    <AuthInputPassword
                        v-model="form.data.password"
                        class="modal-auth-register__field"
                        name="password"
                        :placeholder="$lang.components.modalAuthRegister.password"
                        :invalid="form.validationStatus.password"
                    >
                        {{ form.validationStatus.password }}
                    </AuthInputPassword>

                    <InputCheckbox id="termsAccept" v-model="termsAccept" class="form-register__field">
                        {{ $lang.components.modalAuthRegister.continue_accept_terms }}
                        <NuxtLink no-prefetch :to="`/${$lang.routes.rules}`" rel="nofollow">{{
                            $lang.components.modalAuthRegister.community_rules
                        }}</NuxtLink>
                        {{ $lang.components.modalAuthRegister.continue_accept_terms2 }}
                        <NuxtLink no-prefetch :to="`/${$lang.routes.privacy}`" rel="nofollow">{{
                            $lang.components.modalAuthRegister.privacy_policy
                        }}</NuxtLink>
                        {{ $lang.components.modalAuthRegister.continue_accept_terms3 }}
                    </InputCheckbox>

                    <InputAlert
                        v-if="termsAcceptAlert"
                        class="mb-3"
                        :message="$lang.components.modalAuthRegister.must_accept_terms"
                    />

                    <!-- <InputCheckbox id="newsAccept" v-model="newsAccept" class="form-register__field">{{
                        $lang.components.modalAuthRegister.newsletter
                    }}</InputCheckbox> -->
                </div>

                <ButtonGeneric
                    class="modal-auth-register__submit-button"
                    type="submit"
                    :info="{
                        text: $lang.components.modalAuthRegister.register_account,
                        icon: $assets.primary.logout,
                        handler: () => null,
                    }"
                ></ButtonGeneric>

                <ButtonGeneric
                    type="button"
                    class="modal-auth-register__change-modal-button"
                    :info="{
                        text: $lang.components.modalAuthRegister.have_account,
                        handler: () => changeModal(),
                    }"
                />

                <NuxtTurnstile v-model="turnstileVerified" />
            </form>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import type { Api } from '~~/global'
import { useSocialAuth } from '~/composables/SocialAuth'
import { useRootStore } from '~/store/root'

export type SocialAuthResponse =
    | Api.Responses.Auth.SuccessSignIn
    | {
          error: true
          data: {
              access_token?: string[]
          }
          feedback: 'params_validation_failed'
      }

const props = defineProps({
    pageVersion: {
        type: Boolean,
        default: false,
    },
})

const AuthStore = useAuthStore()
const setCookies = useSetCookies()

const { $assets, $lang } = useNuxtApp()

const { googleAuthInit, facebookAuthInit } = useSocialAuth()

const RootStore = useRootStore()
const turnstileVerified = ref()
watch(turnstileVerified, (val) => {
    console.log(val)
})
const termsAccept = ref(false)
const termsAcceptAlert = ref(false)
const newsAccept = ref(false)
const form = ref({
    data: {
        email: '',
        name: '',
        password: '',
    },
    validationStatus: {
        email: false as string | boolean,
        name: false as string | boolean,
        password: false as string | boolean,
    },
})

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const rrss = [
    {
        text: $lang.components.modalAuthLogin.login_google,
        icon: $assets.social.googleAccount,
        iconAlt: 'Google button',
        handler: () => {
            /* if (
                !turnstileVerified.value ||
                typeof turnstileVerified.value !== 'string' ||
                turnstileVerified.value.length === 0
            ) {
                RootStore.setSiteNotification({
                    text: 'Compruebe que no es un robot',
                    type: 'warning',
                    duration: 8000,
                })
                return
            } */
            googleAuthInit(async (err) => {
                if (err) {
                    return console.log(err)
                }
            })
        },
    },
    {
        text: $lang.components.modalAuthLogin.login_facebook,
        icon: $assets.social.facebookAccount,
        iconAlt: 'Facebook button',
        handler: () => {
            /*  if (
                !turnstileVerified.value ||
                typeof turnstileVerified.value !== 'string' ||
                turnstileVerified.value.length === 0
            ) {
                RootStore.setSiteNotification({
                    text: 'Compruebe que no es un robot',
                    type: 'warning',
                    duration: 8000,
                })
                return
            } */
            AuthStore.setAuthModal({
                type: 'accept-terms',
                info: {
                    message: null,
                    rrssRegister: {
                        rrss: 'facebook',
                        handler: () => {
                            return facebookAuthInit(async (err, response) => {
                                if (err) {
                                    return console.log(err)
                                } else {
                                    if (response) {
                                        RootStore.setLoadingStatus(true)
                                        const result = await $fetch<SocialAuthResponse>(
                                            endpoints.authentication.socialStrategy.facebook,
                                            {
                                                method: 'GET',

                                                headers: buildHeaders(AuthStore.SessionToken),
                                                params: {
                                                    access_token: response.authResponse.accessToken,
                                                },
                                            },
                                        )

                                        if (result.feedback === 'token_created_successfull') {
                                            AuthStore.setAuthModal(null)
                                            setCookies(
                                                result.data.expires_at * 1000,
                                                result.data.access_token,
                                            )

                                            location?.reload()
                                        } else {
                                            RootStore.setLoadingStatus(false)
                                        }
                                    }
                                }
                            })
                        },
                    },
                },
            })
        },
    },
]

const signUp = async () => {
    if (
        !turnstileVerified.value ||
        typeof turnstileVerified.value !== 'string' ||
        turnstileVerified.value.length === 0
    ) {
        RootStore.setSiteNotification({
            text: 'Compruebe que no es un robot',
            type: 'warning',
            duration: 8000,
        })
        return
    }
    if (termsAccept.value) {
        RootStore.setLoadingStatus(true)
        const result = await $fetch<Api.Responses.Auth.SuccessSignUp | Api.Responses.Auth.FailedSignUp>(
            endpoints.authentication.signUp.path,
            {
                method: 'POST',

                body: form.value.data,
                headers: buildHeaders(AuthStore.SessionToken),
                baseURL,
            },
        ).catch((e) => e.data)

        if (result.feedback === 'params_validation_failed') {
            form.value.validationStatus = {
                email: result.data.email?.[0] || false,
                name: result.data.name?.[0] || false,
                password: result.data.password?.[0] || false,
            }
        }

        if (result.feedback === 'singup_success') {
            AuthStore.setAuthModal({
                type: 'email-verification',
                info: {
                    pageVersion: false,
                    isReminder: false,
                },
            })
        }
        RootStore.setLoadingStatus(false)
    } else {
        termsAcceptAlert.value = true
    }
}

const Router = useRouter()
const changeModal = () => {
    if (props.pageVersion) {
        Router.push('/' + $lang.routes.login)
    } else {
        AuthStore.setAuthModal({ type: 'sign-in' })
    }
}
</script>

<style lang="postcss" scoped>
.modal-auth-register {
    @apply mx-auto max-w-[410px] overflow-hidden rounded-xl;
    &__content {
        @apply space-y-3 border-t border-t-gray-200 bg-white p-5 md:flex md:w-full md:flex-none md:flex-col md:justify-center md:border-t-0;
    }
    &__close-button {
        @apply fixed right-4 top-4 flex items-center space-x-1 text-sm text-gray-800;

        img {
            @apply h-4 w-4 saturate-150;
        }
    }
    &__left {
        @apply flex flex-col justify-center space-y-3 p-5 md:w-1/2 md:flex-none;
    }

    &__description {
        @apply text-center text-sm;
    }
    &__change-modal-button {
        @apply w-full border-2 border-site-secondary bg-white text-site-secondary;
    }
    @screen lg {
        &__change-modal-button {
            @apply transition-colors;
            &:hover {
                @apply border-site-secondary bg-site-secondary text-white;
            }
        }
    }
    &__submit-button {
        @apply border-2 border-site-primary bg-white text-site-primary;
    }
    @screen lg {
        &__submit-button {
            @apply transition-colors;
            img {
                @apply transition-all;
            }
            &:hover {
                @apply bg-site-primary-hover text-white transition-colors;
                img {
                    @apply brightness-0 invert;
                }
            }
        }
    }
    &__social-button {
        @apply bg-white lg:transition-colors lg:hover:bg-site-primary lg:hover:text-white;
    }
    &__submit-button,
    &__social-button {
        @apply w-full;
    }

    &__normal-title {
        @apply text-lg font-semibold;
    }
    &__form-login {
        @apply flex h-full flex-col justify-between space-y-3;
    }
}
</style>
